<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">평가실행</h1>
    <div class="box-wrap">
       <div class="box-ct d-flex">
         <div>
            <div class="form-inp sm">
              <v-select
                :items="$store.getters['userStore/GE_USER_COMPANY']"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="gridSearch.ASP_NEWCUST_KEY"
                required
                :rules="[v => !!v || '회사구분은 필수입니다.']"
              >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
              </v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="mixin_common_code_get(allCodeList,'PLT008','전체')"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="유형타입"
                placeholder="선택하세요"
                v-model="gridSearch.QA_TY"
              >
              </v-select>
            </div>
            <v-text-field
              class="form-inp ml-2"
              name="name" label="QA평가명" id="id" outlined hide-details=""
              v-model="gridSearch.QA_NM" @keyup.enter="selectRtnExecutList()">
            </v-text-field>
            <v-text-field
              class="form-inp ml-2"
              name="name" label="QA평가표명" id="id" outlined hide-details=""
              v-model="gridSearch.QA_EVA_NM" @keyup.enter="selectRtnExecutList()">
            </v-text-field>
          </div>
          <div class="ml-auto align-self-center">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="selectRtnExecutList()"> 조회 </v-btn>
          </div>
       </div>
    </div>
    <!-- 할당 리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">할당 리스트</h2>
      <div class="box-ct d-flex">
        <div class="col-8">
          <v-data-table
            dense
            height="150px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            item-key="QA_DATA_ID"
            hide-default-footer
            class="grid-default text-truncate"
            @click:row="setGridSelect"
            :page.sync="gridPaging.page"
            :items-per-page="30"
            @page-count="gridPaging.pageCount = $event"
            no-data-text="검색된 결과가 없습니다."
            :item-class="isActiveRow"
            fixed-header
          >
          <template v-slot:item.QA_DATA_RST_CN="{item}">
            <template v-if="item.QA_DATA_RST_CN == '0'">1차</template>
            <template v-else-if="item.QA_DATA_RST_CN != null">{{item.QA_DATA_RST_CN}}차완료</template>
          </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="gridPaging.page"
              :length="gridPaging.pageCount"
              :total-visible="gridPaging.totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
        <div class="flex-grow-1 ml-2">
          <div>
            <v-text-field
              class="form-inp "
              name="name" label="평가명" id="id" outlined hide-details=""
              v-model="gridSelected.QA_NM"
              readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가대상구분" id="id" outlined hide-details=""
              v-model="gridSelected.QA_TA_DV_NM"
              readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가유형" id="id" outlined hide-details
              v-model="gridSelected.QA_TY_NM"
              readonly>
            </v-text-field>
          </div>
          <div class="mt-2">
            <v-text-field
              v-model="dateRangeText"
              readonly
              outlined
              hide-details
              class="form-inp icon-calendar"
              append-icon="svg-calendar"
              label="평가기간"
            ></v-text-field>
          </div>
          <div class="mt-2">
            <v-text-field
              class="form-inp"
              name="name" label="평가표명" id="id" outlined hide-details="" v-model="gridSelected.QA_EVA_NM" readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가상태" id="id" outlined hide-details="" v-model="getQaSt" readonly>
            </v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="상담사명" id="id" outlined hide-details="" v-model="gridSelected.QA_DATA_US_NM" readonly>
            </v-text-field>
          </div>
          <div class="text-right mt-3">
            <div v-if="gridSelected.QA_TG_TY == '10'" :disabled="gridSelected.QA_DATA_ID != null? false: true">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnHear')" outlined class="btn-etc2"  @click="getAudio()">녹취청취</v-btn>
            </div>
            <div v-if="gridSelected.QA_TG_TY == '20'" :disabled="gridSelected.QA_DATA_ID != null? false: true">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnChat')" outlined class="btn-default ml-2" @click="getChat()">채팅보기</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 평가표 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">평가표
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnBig')" outlined class="btn-default" :disabled="gridSelected.QA_DATA_ID != null? false : true" @click="showDialog('M310301P01')">크게보기 </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" :disabled="(gridSelected.QA_DATA_ID != null && gridSelected.QA_TA_ST != '99')? false : true" @click="common_confirm('저장하시겠습니까?', processRtn,'', null,'', 'done')">저장</v-btn>
        </div>
      </h2>
      <div class="box-ct scrollable" style="height: 302px">
        <div class="table-form">
            <table>
              <caption class="hide">
                항목
              </caption>
              <colgroup>
                <col width="127px" />
                <col width="" />
                <col width="" />
                <col width="" />
                <col width="" />
                <col width="" />
                <col width="" />
                <col width="" />
                <col width="" />
                <col width="" />
                <col width="" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" class="py-2">목록</th>
                  <th scope="col">배점</th>
                  <th scope="col">문항</th>
                  <th scope="col">1차 평가
                    <div v-if="this.mixin_set_btn(this.$options.name, 'btnAllCol1')">
                    <v-btn outlined small class="btn-default"
                    @click="setPerfect('1')"
                    v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN == 'N' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'"
                    >만점</v-btn>
                    </div>
                  </th>
                  <th scope="col">1차 코멘트</th>
                  <th scope="col">1차 이의제기</th>
                  <th scope="col">2차 평가
                    <div v-if="this.mixin_set_btn(this.$options.name, 'btnAllCol2')">
                    <v-btn outlined small class="btn-default"
                    @click="setPerfect('2')"
                    v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN == 'N' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99' && gridSelected.QA_EVA_CN >=2"
                    >만점</v-btn>
                    </div>
                  </th>
                  <th scope="col">2차 코멘트</th>
                  <th scope="col">2차 이의제기</th>
                  <th scope="col">3차 평가
                    <div v-if="this.mixin_set_btn(this.$options.name, 'btnAllCol3')">
                    <v-btn outlined small class="btn-default"
                    @click="setPerfect('3')"
                    v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN == 'N' && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'  && gridSelected.QA_EVA_CN ==3"
                    >만점</v-btn>
                    </div>
                  </th>
                  <th scope="col">종합의견</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center"> 총점수 </td>
                  <td class="text-center">{{gridSelected.QA_EVA_SUM}}</td>
                  <td>
                  </td>
                  <td class="text-center">{{getCn1Sc}}</td>
                  <td class="text-center">
                  </td>
                  <td class="text-center">
                  </td>
                  <td class="text-center">{{getCn2Sc}}</td>
                  <td class="text-center">
                  </td>
                  <td class="text-center">
                  </td>
                  <td class="text-center">{{getCn3Sc}}</td>
                  <td>
                  </td>
                </tr>
                  <template v-for="(item, index) in qaQsList">
                    <tr :key="index">
                      <template>
                        <!-- <v-text-field v-show="false" v-model="dataRstList[index].QA_QS_VE_ID" :value="item.QA_QS_VE_ID"></v-text-field> -->
                      </template>
                      <template v-if="index == 0 || item.QA_QS_ID != qaQsList[index - 1].QA_QS_ID">
                        <td class="text-center" :rowspan="item.VE_COUNT">{{item.QA_QS_INDEX}}. {{item.QA_QS_NM}}({{item.QA_QS_TY_SC}}점)</td>
                      </template>
                      <td class="text-center">{{item.QA_QS_VE_SC}}</td>
                      <td>
                        {{item.QA_QS_VE_RT}}
                      </td>
                      <td class="text-center">
                        <!-- 객관식 -->
                        <template v-if="item.QA_QS_TY == '10'">
                          <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                            <input type="radio"
                            class="radio_rst"
                            :id="'radio_rst1_' + item.QA_QS_VE_ID"
                            :name="'radio_rst1_' + item.QA_QS_ID"
                            :value="item.QA_QS_VE_ID"
                            :checked="item.QA_QS_VE_ID_1 == item.QA_QS_VE_ID? true: false"
                            @change="setSc('1')"
                            />
                          </template>
                          <template v-else>
                            <v-icon small v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''">mdi-check-circle</v-icon>
                          </template>
                        </template>
                        <!-- 서술형-->
                        <template v-else-if="item.QA_QS_TY == '20'">
                          <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                            <v-text-field
                             class="form-inp sm ml-2"
                              name="name" id="id" outlined hide-details=""
                              type="number"
                              min="0"
                              :max="item.QA_QS_VE_SC"
                              v-model="item.QA_DATA_RST_SUM_1"
                              @change="setSc('1')"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            {{item.QA_DATA_RST_SUM_1}}
                          </template>
                        </template>
                        <!-- 체크박스 -->
                        <template v-else-if="item.QA_QS_TY == '30'">
                          <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                              <v-checkbox
                                class="d-inline-flex"
                                :on-icon="'svg-chk-on'"
                                :off-icon="'svg-chk-off'"
                                hide-details
                                :id="'qaVe_'+index"
                                :name="'qaVe_' + item.QA_QS_ID"
                                v-model="item.QA_QS_VE_ID_1"
                                :true-value="item.QA_QS_VE_ID"
                                false-value=""
                                @change="setSc('1')"
                                >
                              </v-checkbox>
                          </template>
                          <template v-else>
                            <v-icon small v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''">mdi-check-circle</v-icon>
                          </template>
                        </template>
                      </td>
                      <td class="text-center">
                        <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FS_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '10' || gridSelected.QA_TA_ST == '20') && gridSelected.QA_TA_ST != '99'">
                          <v-text-field
                             class="form-inp sm ml-2"
                              name="name" id="id" outlined hide-details=""
                              v-model="item.QA_DATA_RST_COM_1"
                              v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''? true: false"
                              max="500"
                            ></v-text-field>
                        </template>
                        <template v-else>
                          {{item.QA_DATA_RST_COM_1}}
                        </template>
                      </td>
                      <td class="text-center">
                        <template v-if="item.QA_TA_ST == '25' && QA_MANAGE_YN == 'N' && gridSelected.FS_RST_SAVE_YN == 'Y' && item.QA_DATA_RST_COM_RE_SAVE_YN_1 == 'N' && gridSelected.QA_TA_ST != '99'">
                          <v-text-field
                             class="form-inp sm ml-2"
                              name="name" id="id" outlined hide-details=""
                              v-model="item.QA_DATA_RST_COM_RE_1"
                              v-if="item.QA_QS_VE_ID_1 != null && item.QA_QS_VE_ID_1 != ''? true: false"
                              max="500"
                            ></v-text-field>
                        </template>
                        <template v-else>
                          {{item.QA_DATA_RST_COM_RE_1}}
                        </template>
                      </td>
                      <!-- 2차 평가 -->
                      <td class="text-center">
                        <template v-if="gridSelected.QA_EVA_CN >= 2">
                          <!-- 객관식 -->
                          <template v-if="item.QA_QS_TY == '10'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                              <input type="radio"
                              class="radio_rst"
                              :id="'radio_rst2_' + item.QA_QS_VE_ID"
                              :name="'radio_rst2_' + item.QA_QS_ID"
                              :value="item.QA_QS_VE_ID"
                              :checked="item.QA_QS_VE_ID_2 == item.QA_QS_VE_ID? true: false"
                              @change="setSc('2')"
                              />
                            </template>
                            <template v-else>
                              <v-icon small v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''">mdi-check-circle</v-icon>
                            </template>
                          </template>
                          <!-- 서술형-->
                          <template v-else-if="item.QA_QS_TY == '20'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y'  && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                              <v-text-field
                              class="form-inp sm ml-2"
                                name="name" id="id" outlined hide-details=""
                                type="number"
                                min="0"
                                :max="item.QA_QS_VE_SC"
                                v-model="item.QA_DATA_RST_SUM_2"
                                @change="setSc('2')"
                              ></v-text-field>
                            </template>
                            <template v-else>
                              {{item.QA_DATA_RST_SUM_2}}
                            </template>
                          </template>
                          <!-- 체크박스 -->
                          <template v-else-if="item.QA_QS_TY == '30'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                                <v-checkbox
                                  class="d-inline-flex"
                                  :on-icon="'svg-chk-on'"
                                  :off-icon="'svg-chk-off'"
                                  hide-details
                                  :id="'qaVe_'+index"
                                  :name="'qaVe_' + item.QA_QS_ID"
                                  v-model="item.QA_QS_VE_ID_2"
                                  :true-value="item.QA_QS_VE_ID"
                                  false-value=""
                                  @change="setSc('2')"
                                  >
                                </v-checkbox>
                            </template>
                            <template v-else>
                              <v-icon small v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''">mdi-check-circle</v-icon>
                            </template>
                          </template>
                        </template>
                      </td>
                      <td class="text-center">
                        <template v-if="gridSelected.QA_EVA_CN >= 2">
                          <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.SC_RST_SAVE_YN != 'Y' && (gridSelected.QA_TA_ST == '25' || gridSelected.QA_TA_ST == '30') && gridSelected.QA_TA_ST != '99'">
                            <v-text-field
                              class="form-inp sm ml-2"
                                name="name" id="id" outlined hide-details=""
                                v-model="item.QA_DATA_RST_COM_2"
                                v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''? true: false"
                                max="500"
                              ></v-text-field>
                          </template>
                          <template v-else>
                            {{item.QA_DATA_RST_COM_2}}
                          </template>
                        </template>
                      </td>
                      <td class="text-center">
                        <template v-if="gridSelected.QA_EVA_CN >= 2">
                          <template v-if="item.QA_TA_ST == '35' && QA_MANAGE_YN == 'N' && gridSelected.SC_RST_SAVE_YN == 'Y' && item.QA_DATA_RST_COM_RE_SAVE_YN_2 == 'N' && gridSelected.QA_TA_ST != '99'">
                            <v-text-field
                              class="form-inp sm ml-2"
                                name="name" id="id" outlined hide-details=""
                                v-model="item.QA_DATA_RST_COM_RE_2"
                                v-if="item.QA_QS_VE_ID_2 != null && item.QA_QS_VE_ID_2 != ''? true: false"
                                max="500"
                              ></v-text-field>
                          </template>
                          <template v-else>
                            {{item.QA_DATA_RST_COM_RE_2}}
                          </template>
                        </template>
                      </td>
                      <!-- 3차 평가 -->
                      <td class="text-center">
                        <template v-if="gridSelected.QA_EVA_CN == 3">
                          <!-- 객관식 -->
                          <template v-if="item.QA_QS_TY == '10'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN != 'Y'  && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'">
                              <input type="radio"
                              class="radio_rst"
                              :id="'radio_rst3_' + item.QA_QS_VE_ID"
                              :name="'radio_rst3_' + item.QA_QS_ID"
                              :value="item.QA_QS_VE_ID"
                              :checked="item.QA_QS_VE_ID_3 == item.QA_QS_VE_ID? true: false"
                              @change="setSc('3')"
                              />
                            </template>
                            <template v-else>
                              <v-icon small v-if="item.QA_QS_VE_ID_3 != null && item.QA_QS_VE_ID_3 != ''">mdi-check-circle</v-icon>
                            </template>
                          </template>
                          <!-- 서술형-->
                          <template v-else-if="item.QA_QS_TY == '20'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN != 'Y'  && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'">
                              <v-text-field
                              class="form-inp sm ml-2"
                                name="name" id="id" outlined hide-details=""
                                type="number"
                                min="0"
                                :max="item.QA_QS_VE_SC"
                                v-model="item.QA_DATA_RST_SUM_3"
                                @change="setSc('3')"
                              ></v-text-field>
                            </template>
                            <template v-else>
                              {{item.QA_DATA_RST_SUM_3}}
                            </template>
                          </template>
                          <!-- 체크박스 -->
                          <template v-else-if="item.QA_QS_TY == '30'">
                            <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.FN_RST_SAVE_YN != 'Y'  && (gridSelected.QA_TA_ST == '35' || gridSelected.QA_TA_ST == '40') && gridSelected.QA_TA_ST != '99'">
                                <v-checkbox
                                  class="d-inline-flex"
                                  :on-icon="'svg-chk-on'"
                                  :off-icon="'svg-chk-off'"
                                  hide-details
                                  :id="'qaVe_'+index"
                                  :name="'qaVe_' + item.QA_QS_ID"
                                  v-model="item.QA_QS_VE_ID_3"
                                  :true-value="item.QA_QS_VE_ID"
                                  false-value=""
                                  @change="setSc('3')"
                                  >
                                </v-checkbox>
                            </template>
                            <template v-else>
                              <v-icon small v-if="item.QA_QS_VE_ID_3 != null && item.QA_QS_VE_ID_3 != ''">mdi-check-circle</v-icon>
                            </template>
                          </template>
                        </template>
                      </td>
                      <template v-if="index == 0">
                        <td :rowspan="qaQsList.length">
                          <template v-if="QA_MANAGE_YN == 'Y' && gridSelected.QA_TA_ST != '99'">
                            <v-textarea
                              no-resize
                              outlined
                              v-model="item.QA_DATA_RST_TCOM"
                            >
                            </v-textarea>
                          </template>
                          <template v-else>
                            {{item.QA_DATA_RST_TCOM}}
                          </template>
                        </td>
                      </template>
                    </tr>
                  </template>
              </tbody>
            </table>
        </div>
      </div>
    </div>
    <!-- 크게보기 모달 -->
    <v-dialog max-width="1600" persistent v-model="dialogM310301P01"
      content-class="square-modal modal-white"
    >
      <dialog-M310301P01
        v-if="dialogM310301P01"
        :gridSelected="gridSelected"
        :qaQsList="qaQsList"
        :QA_MANAGE_YN="QA_MANAGE_YN"
        @hide="hideDialog('M310301P01')"
        @submit="submitDialog('M310301P01')"
        @getAudio="getAudio()"
        @getChat="getChat()"
      >
      </dialog-M310301P01>
    </v-dialog>

  </div>

</template>

<script>
import DialogM310301P01 from "./M310301P01.vue";
import api from '@/store/apiUtil.js'
import {mixin} from '@/mixin/mixin.js'

export default {
  name: "MENU_M310301", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM310301P01
  },
  mixins : [mixin],
  data() {
    return {
      dialogM310301P01: false,
      menu1: false,
      searchValid : false,
      QA_MANAGE_YN : 'N',
      dropItems: [{ title: "option1" }],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "ROW_NUMBER",
          width: "50px",
          sortable: true,
        },
        { text: "평가표명", value: "QA_EVA_NM", align: "center", sortable: true },
        { text: "평가상태", value: "QA_DATA_RST_CN", align: "center", sortable: true },
        { text: "1차 점수", value: "FS_RST_SUM", align: "center", sortable: true },
        { text: "2차 점수", value: "SC_RST_SUM", align: "center", sortable: true },
        { text: "3차 점수", value: "FN_RST_SUM", align: "center", sortable: true },
        { text: "QA명", value: "QA_NM", align: "center", sortable: true },
        { text: "상담사명", value: "QA_DATA_US_NM", align: "center", sortable: true },
        { text: "상담시간", value: "CNSL_TIME", align: "center", sortable: true },
        { text: "상담유형1", value: "QA_CNSL_TYP_CD_NM", align: "center", sortable: true },
        { text: "상담유형2", value: "QA_CNSL_TYP_CD_NM_2", align: "center", sortable: true },
        { text: "상담유형3", value: "QA_CNSL_TYP_CD_NM_3", align: "center", sortable: true },
        { text: "상담유형4", value: "QA_CNSL_TYP_CD_NM_4", align: "center", sortable: true },
      ],
      gridDataText: [],
      gridSearch: {},
      gridSelected : {},
      allCodeList : [],
      groupCdList : ['PLT008'],
      gridPaging: {
        page : 1,
        itemsPerPage : 5,
        pageCount : 10,
        totalVisible : 10,
      },
      qaQsList : [],
      dataRstList : [{
        QA_QS_VE_ID :""
      }],
      radioGroup: [
      ],
    };
  },
  created(){
    //this.getCmmCd(this.groupCdList,'전체');
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  },
  methods: {
    //할당 리스트 조회
    async selectRtnExecutList(){
      this.gridSelected = {};
      this.qaQsList = [];
      if(this.gridSearch.ASP_NEWCUST_KEY == undefined || this.gridSearch.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        let URLData = "api/phone/qa2/qa-execut/list";
        let HEADER_SERVICE = "phone.qa2.qa-execut";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        let jsonData = {
          ASP_NEWCUST_KEY : this.gridSearch.ASP_NEWCUST_KEY,
          LOGIN_ID : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          QA_NM : this.gridSearch.QA_NM,
          QA_EVA_NM : this.gridSearch.QA_EVA_NM,
          QA_TY : this.gridSearch.QA_TY,
        };

        await api.post(URLData, jsonData, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          if(typeof(response) != 'string'){
            if(response.data.DATA.length > 0 && response.data.DATA[0].QA_DATA_ID != undefined && response.data.DATA[0].QA_DATA_ID != ''){
              this.gridDataText = response.data.DATA;
              this.QA_MANAGE_YN = response.data.DATA[0].QA_MANAGE_YN;
            }else{
              this.gridDataText = [];
              this.QA_MANAGE_YN = 'N'
            }
          }else{
            this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
          }
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        })
      }
    },
    async selectRtnExecutQaQs(){
      let URLData = "api/phone/qa2/qa-execut/qa-qs/list";
      let HEADER_SERVICE = "phone.qa2.qa-execut.qa-qs";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = this.gridSelected;

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
        }
      })
      .then((response) => {
        if(typeof(response) != 'string'){
          let radioList = document.getElementsByClassName("radio_rst")
          for(let i = 0 ; i < radioList.length; i++){
            radioList[i].checked = false;
          }
          this.qaQsList = response.data.DATA;

          if(this.gridSelected.QA_TA_ST == '25' || this.gridSelected.QA_TA_ST == '30'){
            if(this.gridSelected.QA_FS_CP_YN == 'Y' && this.QA_MANAGE_YN == 'Y' && this.gridSelected.SC_RST_SAVE_YN != 'Y' && (this.gridSelected.QA_TA_ST == '25' || this.gridSelected.QA_TA_ST == '30')){
              this.qaQsList.map(e=> {
                if(e.QA_QS_VE_ID_2 == null || e.QA_QS_VE_ID_2 == ''){
                  if(e.QA_QS_VE_ID_1 != null && e.QA_QS_VE_ID_1 != ""){
                    e.QA_QS_VE_ID_2 = e.QA_QS_VE_ID_1;
                    e.QA_DATA_RST_SUM_2 = e.QA_DATA_RST_SUM_1;
                    if(e.QA_QS_TY == '10'){
                      document.querySelector('input[id="radio_rst2_' + e.QA_QS_VE_ID_2 +'"]:checked');
                    }
                  }
                }
              });
            }
          }
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },
    //녹취파일 조회
    async selectRtnPhoneCnslHist(){
      let retData = "";
      let jsonData = {
        CNSL_HIST_NO : this.gridSelected.CNSL_HIST_NO
      }

      let URLData = "api/phone/main/unity-cnsl-hst-phone/inqire";
      let HEADER_SERVICE = "phone/main";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      await api.post(URLData, jsonData, {
        head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
      }).then((response) =>{
        retData = response.data.DATA;
      }).catch((error) =>{
         this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })

      return retData;
    },
    //채팅 이력 조회
    async selectRtnChatCnslHist(){
      let retData = "";
      let jsonData = {
        TALK_CONTACT_ID : this.gridSelected.TALK_CONTACT_ID
      }

      let URLData = "api/chat/main/cnslt-cn/inqire";
      let HEADER_SERVICE = "chat.main.cnslt-cn";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      await api.post(URLData, jsonData, {
        head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
      }).then((response) =>{
        retData = response.data.DATA;
      }).catch((error) =>{
         this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })

      return retData;
    },
    getAudio(){
      if(this.gridSelected.CNSL_HIST_NO != null && this.gridSelected.CNSL_HIST_NO != ''){
        let retData = this.selectRtnPhoneCnslHist();
        console.log(JSON.stringify(retData));
        if(retData != undefined && retData.audioUrl != '' && retData.audioUrl != undefined){

        }else{
          this.common_alert("녹취파일 정보가 없습니다.","noti");
        }
      }else{
        this.common_alert("녹취파일 정보가 없습니다.","noti");
      }
    },
    getChat(){
      if(this.gridSelected.TALK_CONTACT_ID != null && this.gridSelected.TALK_CONTACT_ID != ''){
        let retData = this.selectRtnChatCnslHist();
        if(retData != undefined && retData.audioUrl != '' && retData.audioUrl != undefined){

        }else{
          this.common_alert("채팅 정보가 없습니다.","noti");
        }
      }else{
        this.common_alert("채팅 정보가 없습니다.","noti");
      }
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this.common_confirm('저장하시겠습니까?', this.submitProcessRtn, '', null, '', 'chk')
    },
    submitProcessRtn(){
      this.processRtn();
      this.hideDialog('M310301P01');
    },
    setGridSelect(item){
      this.gridSelected = item;
      this.selectRtnExecutQaQs();
    },
    async processRtn(){
      let cn = 0;

      if(this.gridSelected.QA_TA_ST == '10' || this.gridSelected.QA_TA_ST == '20'){
        cn = 1;
      }else if(this.gridSelected.QA_TA_ST == '25' || this.gridSelected.QA_TA_ST == '30'){
        cn = 2;
      }else if(this.gridSelected.QA_TA_ST == '35' || this.gridSelected.QA_TA_ST == '40'){
        cn = 3;
      }

      this.setVeId(cn);

      let URLData = "api/phone/qa2/qa-execut/regist";
      let HEADER_SERVICE = "phone.qa2.qa-execut";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";
      let DATA_OBJECT = "dataRstList";

      let jsonData = {
        QA_ID : this.gridSelected.QA_ID,
        QA_TA_ST : this.gridSelected.QA_TA_ST,
        REG_ID : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_NEWCUST_KEY : this.gridSearch.ASP_NEWCUST_KEY,
        LOGIN_ID : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        QA_DATA_RST_CN : cn,
        dataRstList : this.qaQsList,
        QA_MANAGE_YN : this.QA_MANAGE_YN,
        QA_DATA_ID : this.gridSelected.QA_DATA_ID,
      };


      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
          DATA_OBJECT : DATA_OBJECT,
        }
      })
      .then((response) => {
        if(typeof(response) != 'string'){
          this.common_alert("정상처리 되었습니다.","done");
          this.selectRtnExecutList();
          this.selectRtnExecutQaQs();
          if(cn == 1){
            this.gridSelected.FS_RST_SAVE_YN = 'Y';
          }else if (cn == 2){
            this.gridSelected.SC_RST_SAVE_YN = 'Y';
          }else if (cn == 3){
            this.gridSelected.FN_RST_SAVE_YN = 'Y';
          }
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })

    },
    //만점 처리
    setPerfect(rst_index){
      let max_sc = 0;
      let max_index = '';
      let max_qa_qs_ve_id = '';
      let qa_qs_id = '';

      this.qaQsList.map(e =>{
        e['QA_QS_VE_ID_' + rst_index] = "";
        e['QA_DATA_RST_SUM_' + rst_index] = 0;
        e['QA_DATA_RST_COM_' + rst_index] = "";
      })

      this.qaQsList.map((e,index)=>{
        if(e.QA_QS_TY == '10'){
          if(qa_qs_id != e.QA_QS_ID && max_sc != 0 && max_sc != null){
            this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
            document.querySelector('input[id="radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
            this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_TY_SC;

            max_sc = e.QA_QS_VE_SC
            max_index = index;
            max_qa_qs_ve_id = e.QA_QS_VE_ID;
            qa_qs_id = e.QA_QS_ID;
          }else{
            if(max_sc < e.QA_QS_VE_SC){
              max_sc = e.QA_QS_VE_SC
              max_index = index;
              max_qa_qs_ve_id = e.QA_QS_VE_ID;
              qa_qs_id = e.QA_QS_ID;
            }
          }
        }else if(e.QA_QS_TY == '20'){
          if(max_index != ''){
            this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
            document.querySelector('input[id="radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
            this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_SC;
            max_sc = 0;
            max_index = '';
            max_qa_qs_ve_id = '';
            qa_qs_id = '';
          }
          e['QA_DATA_RST_SUM_' + rst_index] = e.QA_QS_VE_SC;
          e['QA_QS_VE_ID_' + rst_index] = e.QA_QS_VE_ID;
        }else if(e.QA_QS_TY == '30'){
          if(max_index != ''){
            this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
            document.querySelector('input[id="radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
            this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_SC;
            max_sc = 0;
            max_index = '';
            max_qa_qs_ve_id = '';
            qa_qs_id = '';
          }
          e['QA_QS_VE_ID_' + rst_index] = e.QA_QS_VE_ID;
          e['QA_DATA_RST_SUM_' + rst_index] = e.QA_QS_VE_SC;
        }
      });

      if(max_index != ''){
        this.qaQsList[max_index]['QA_QS_VE_ID_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_ID;
        document.querySelector('input[id="radio_rst'+ rst_index +'_' + max_qa_qs_ve_id +'"]').checked = true;
        this.qaQsList[max_index]['QA_DATA_RST_SUM_' + rst_index] = this.qaQsList[max_index].QA_QS_VE_SC;
        max_sc = 0;
        max_index = '';
        max_qa_qs_ve_id = '';
        qa_qs_id = '';
      }
    },
    setSc(rst_index){
      this.setVeId(rst_index);

      this.qaQsList.map(e =>{
        if(e.QA_QS_TY == '10' || e.QA_QS_TY == '30'){
          if(e['QA_QS_VE_ID_' + rst_index] != null && e['QA_QS_VE_ID_' + rst_index] != ''){
            e['QA_DATA_RST_SUM_' + rst_index] = e.QA_QS_VE_SC;
          }else{
            e['QA_DATA_RST_SUM_' + rst_index] = null;
            e['QA_DATA_RST_COM_' + rst_index] = "";
          }
        }
      });
    },
    setVeId(rst_index){
      this.qaQsList.map(e=>{
        if(e.QA_QS_TY == '10'){
          if(document.querySelector('input[id="radio_rst'+ rst_index +'_' + e.QA_QS_VE_ID +'"]:checked') != null){
            e['QA_QS_VE_ID_'+ rst_index] = document.querySelector('input[id="radio_rst'+ rst_index +'_' + e.QA_QS_VE_ID +'"]').value;
          }else{
            e['QA_QS_VE_ID_'+ rst_index] = null;
          }
        }else if(e.QA_QS_TY == '20'){
          e['QA_QS_VE_ID_'+ rst_index] = e.QA_QS_VE_ID;
        }
      });
    },
    isActiveRow(item){
      return item.QA_DATA_ID == this.gridSelected.QA_DATA_ID? 'active':'';
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.gridSelected.QA_ST_DTTM;
      var newEndDate = this.gridSelected.QA_EN_DTTM;
      if(newStartDate == null){
        newStartDate = '';
      }

      if(newEndDate == null){
        newEndDate = '';
      }
      return newStartDate + " ~ " + newEndDate;
    },
    getQaSt(){
      let cn = this.gridSelected.QA_DATA_RST_CN
      let ret = "";
      if(cn == 0){
        ret = "1차"
      }else if(cn == null){
        ret = '';
      }else{
        ret = cn +"차완료"
      }
      return ret;
    },
    getCn1Sc(){
      let sc = 0;
      this.qaQsList.map(e => {
        if(e.QA_DATA_RST_SUM_1 != null && e.QA_DATA_RST_SUM_1 > 0){
          sc += parseInt(e.QA_DATA_RST_SUM_1);
        }
      })
      return sc;
    },
    getCn2Sc(){
      let sc = 0;
      this.qaQsList.map(e => {
        if(e.QA_DATA_RST_SUM_2 != null && e.QA_DATA_RST_SUM_2 > 0){
          sc += parseInt(e.QA_DATA_RST_SUM_2);
        }
      })
      return sc;
    },
    getCn3Sc(){
      let sc = 0;
      this.qaQsList.map(e => {
        if(e.QA_DATA_RST_SUM_3 != null && e.QA_DATA_RST_SUM_3 > 0){
          sc += parseInt(e.QA_DATA_RST_SUM_3);
        }
      })
      return sc;
    },
  },
};
</script>

<style>

</style>
